const SERVER_DEV = 'http://localhost/webapisimi/api/'
const SERVER_PROD = 'https://portalmatus.com/webapisimi/api/'

const environment = {
  production: true,
  api: SERVER_PROD,
  emails: {
    grua: 'gruas@matusintl.com',
    //seabordMarine: 'loi@seaboardmarine.com'
    seabordMarine: 'loi@seaboardmarine.com'
  }
}

export default environment;

export const server = environment.api;
//export const server = 'http://localhost:5047/api/';

/**
 * example of production environment
 */

// const environment = {
//   production: true,
//   server: SERVER_PROD,
//   emails: {
//     grua: 'gruas@matusintl.com'
//   }
// }
