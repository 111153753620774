import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {viewWR} from '../Servicios/WerehouseService';
import {
  editWerehouse,
} from '../Servicios/WerehouseService';
import {toast} from 'react-toastify';
import Spinner from "../shared/spinner";
import WarehouseForm from "./WarehouseForm";

const EditWR = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const notify = () => toast.success("Se actualizo informacion correctamente", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  const [editWR, setEditWR] = useState([]);

  const editarWR = async (values) => {
    try {
      const input = {
        ...editWR,
        ...values,
        subastaId: +values.subastaId,
        importadoraId: +values.importadoraId,
        quantity: +values.quantity,
        schedulebId: +values.schedulebId,
        stateId: +values.stateId,
        typeMerchId: +values.typeMerchId,
        originId: +values.originId,
        licenceTypeId: +values.licenceTypeId,
        expoCodeId: +values.expoCodeId,
        aduanaId: +values.aduanaId,
      }

      setIsLoading(true);
      const data = await editWerehouse(id, input);
      setIsLoading(false);

      if (data) {
        notify();
        setTimeout(() => {
          return navigate(`/viewWereHouse/${id}`);
        }, 1000);
      }
    } catch (ex) {
      setIsLoading(false);
    }
  }

  const visualizarwr = () => {
    setIsLoading(true);
    viewWR(id).then((whr) => {
      setEditWR(whr);
    }).finally(() => {
      setIsLoading(false)
    });
  };

  useEffect(() => {
    visualizarwr();
  }, [id]);

  return (
    <Spinner loading={isLoading}>
      <WarehouseForm werehouse={editWR}
                     onUpdate={visualizarwr}
                     onSubmit={editarWR} />
    </Spinner>
  );
}

export default EditWR;
