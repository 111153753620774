import {
  Button,
  Steps,
  theme,
  Col,
  Row,
  Space,
} from "antd";
import React, {useEffect, useState, useRef} from "react";
import {
  getExportationPdf,
  updateExportation,
  getExportationIdData,
  exportationContainerAsignar,
  exportationContainerRemover,
} from "../../../Servicios/ExportationServices";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import useCurrentUser from "../../../Hooks/UserHook";
import Spinner from "../../../shared/spinner";
import ComponentToPrint from "../pdf";
import {useNavigate, useParams} from "react-router";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import PdfReport from "../../../utils/pdfReport";
import ExportationBookingForm from "./ExportationBookingForm";
import AsignacionContenedor from "./AsignacionContenedor";
import {enviarEmail} from "../../../Servicios/SolicitudesService";
import environment from "../../../environments/environment";
import {
  NotificacionProgramacionCargaExportation
} from "../../../Resources/HtmlEmailBody/SolicitudRecoleccionVehiculo";
import ExportationAdditionalForm from "./ExportationAdditionalForm";

const ExportationFormPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  let componentRef = useRef();

  const [loading, setIsLoading] = useState(!!id);
  const [tipSpinner, setTipSpinner] = useState("");
  const [exportation, setExportation] = useState(undefined);

  const updateAndSaveInformationAditional = async (values) => {
    try {
      setTipSpinner("Actualizando Información Adicional");

      values.dateNotShipment = values.dateNotShipment
        ? moment(values.dateNotShipment.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotArrive = values.dateNotArrive
        ? moment(values.dateNotArrive.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotEmtied = values.dateNotEmtied
        ? moment(values.dateNotEmtied.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateReceivingRTU = values.dateReceivingRTU
        ? moment(values.dateReceivingRTU.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateUpdateRTU = values.dateUpdateRTU
        ? moment(values.dateUpdateRTU.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.dateNotZarpe = values.dateNotZarpe
        ? moment(values.dateNotZarpe.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        : null;

      values.containerId = exportation?.containerId;
      values.countryId = exportation?.countryId;
      values.createDate = exportation?.createDate;
      values.state = exportation?.state;
      values.isActive = exportation?.isActive;
      values.amountAditional = exportation?.amountAditional;
      values.countryName = exportation?.countryName;
      values.customerReference = exportation?.customerReference;
      values.dateLoading = exportation?.dateLoading;
      values.approvalNumber = exportation?.approvalNumber;
      values.sealNumber = exportation?.sealNumber;
      values.userName = exportation?.userName;
      values.descriptionAditional = exportation?.descriptionAditional;
      values.noteLoading = exportation?.noteLoading;

      values.exportationId = exportation?.exportationId;

      const input = {
        ...exportation,
        ...values
      };

      setIsLoading(true);
      await updateExportation(input);
      setIsLoading(false);

      successMessage('Exportacion actualizada con exito');
      navigate('/exportation/list');
    } catch (ex) {
      errorMessage(`${ex.toString()}`);
    }
  };

  useEffect(() => {
    if (currentUser && id) {
      initExportation().then();
    }
  }, [currentUser, id]);

  const initExportation = async () => {
    setIsLoading(true);
    const data = await getExportationIdData(id);
    setIsLoading(false);

    setExportation({...data});
  }

  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");

  const {token} = theme.useToken();
  const [current, setCurrent] = useState(0);

  const handleSaveExportationBooking = async (newExportation) => {
    if (exportation) {
      setCurrent(1);
      setExportation(newExportation);
    } else {
      navigate('/exportation/list');
    }
  }

  const handleSaveExportationContainer = async () => {
    await initExportation();
  }

  const handleSelectContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerAsignar(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor asignado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const handleDeleteContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerRemover(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor eliminado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const enviarCorreo = async (exportation) => {
    try {
      const date = moment().format("DD-MM-YYYY");
      await enviarEmail({
        to: [environment.emails.grua],
        cc: [currentUser?.email],
        subject: `${date} - VEHÍCULO PROXIMOS A CARGARSE CON ${exportation?.carrier?.oceanCarrierName} CON DESTINO ${exportation?.aduana?.nameAduana}`,
        body: NotificacionProgramacionCargaExportation(exportation),
        attachedFile: [],
      });

      successMessage("Programa de carga agregado con exito");
    } catch (error) {
      errorMessage(error.toString());
    }
  };

  const handleImprimir = async (exportation) => {
    setIsLoading(true);
    setTipSpinner("Cargando PDF....");
    const data = await getExportationPdf(exportation.exportationId)
      .finally(() => setIsLoading(false));

    if (data.status) {
      setVisible(true);
      setDataReport(data.data);
      await enviarCorreo(exportation);
    } else {
      setVisible(false);
      errorMessage(data.message);
    }
  }

  const steps = [
    {
      title: "Control de Booking",
      content: () => {
        return (
          <div className="p-3">
            <ExportationBookingForm exportation={exportation}
                                    onSubmit={handleSaveExportationBooking}/>
          </div>
        );
      },
    },
    {
      title: 'Asignación de contenedores',
      content: () => {
        return (
          <div className="p-3">
            <AsignacionContenedor origen={currentUser?.idubc}
                                  exportation={exportation}
                                  onSelectContainer={handleSelectContainer}
                                  onDeleteContainer={handleDeleteContainer}
                                  onUpdate={handleSaveExportationContainer}/>

            <div className="row">
              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(0)}>
                    Atras
                  </Button>
                </Space>
              </div>

              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button type="primary" block onClick={() => setCurrent(2)}>
                    Continuar
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Imprimir Documento",
      content: () => {
        return (
          <div>
            <PdfReport
              visible={visible}
              setVisible={setVisible}
              data={dataReport}
              setData={setDataReport}
            />
            <ComponentToPrint
              ref={(component) => (componentRef = component)}
              exportation={exportation}
            />
            <Row gutter={[16, 24]} style={{marginBottom: 10}}>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button
                    block
                    style={{backgroundColor: "green", color: "white"}}
                    onClick={() => handleImprimir(exportation)}>
                    Imprimir
                  </Button>
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(current - 1)}>
                    {" "}
                    Atras{" "}
                  </Button>
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      setCurrent(current + 1);
                    }}>
                    Continuar
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Información Adicional",
      content: () => {
        return (
          <div className="p-3">
            <ExportationAdditionalForm goBack={() => setCurrent(current - 1)}
                                       exportation={exportation}
                                       currentUser={currentUser}
                                       updateAndSaveInformationAditional={updateAndSaveInformationAditional} />
          </div>
        );
      },
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    // lineHeight: "260px",
    // textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      <Spinner loading={loading} tip={tipSpinner ? tipSpinner : "Cargando..."}>
        <Row gutter={[16, 24]}>
          <Col
            span={24}
            style={{display: "flex", justifyContent: "space-between"}}
          >
            {id ? (
              <h4>Actualizar datos de exportación</h4>
            ) : (
              <h4>Registro de exportaciones</h4>
            )}
            <Button
              type="primary"
              onClick={() => navigate("/exportation/list")}
            >
              Lista de exportaciones
            </Button>
          </Col>
        </Row>
        <hr/>
        <Steps current={current} items={items}/>
        <div style={contentStyle}>{steps[current].content()}</div>
      </Spinner>
    </>
  );
};

export default ExportationFormPage;
