import {Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
  getImportadora,
  getModeTransportation, getPortCode,
  getServiceType,
  getSubastas
} from "../../../Servicios/ExportationServices";
import PortCodeForm from "../../PortCode/PortCodeForm";
import ImportadoraForm from "../../../Componentes/ImportadoraForm/ImportadoraForm";
import Spinner from "../../../shared/spinner";
import moment from "moment/moment";

const {TextArea} = Input;

const onChange = () => {};

const onSearch = () => {};

const ExportationAdditionalForm = ({exportation, currentUser, updateAndSaveInformationAditional, goBack}) => {
  const [form] = Form.useForm();

  const [optionSelectSubasta, setSelectSubasta] = useState([]);
  const [optionSelectPortCode, setSelectPortCode] = useState([]);
  const [optionSelectImportadora, setSelectImportadora] = useState([]);
  const [optionSelectServiceType, setSelectServiceType] = useState([]);
  const [optionSelectModeTransportation, setSelectModeTransportation] = useState([]);
  const [optionSelectPortCodeDesrtination, setSelectPortCodeDestination] = useState([]);

  const [portCode, setPortCode] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showAddConsignee, setShowAddConsignee] = useState(false);
  const [optionSelectDisableForwading, setOptionSelectDisableForwading] = useState(false);
  const [optionSelectDisableNotifyParty, setOptionSelectDisableNotifyParty] = useState(false);

  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        userName: currentUser?.fullName,
        userId: parseInt(currentUser?.idusr),
        placeExId: parseInt(currentUser?.idubc),
      });

      setLoading(true);
      if (exportation) {
        fetch(exportation).then().finally(() => setLoading(false));
        setExportationForm(exportation).then();
      } else {
        fetch().then().finally(() => setLoading(false));
      }
    }
  }, [exportation, currentUser]);

  const setExportationForm = async (exportation) => {
    if (!exportation) return;

    form.setFieldsValue({
      shipmentName: exportation?.shipmentName,
      billLadingNum: exportation?.billLadingNum,
      bookingNum: exportation?.bookingNum,
      containerId: exportation?.containerId,
      amountAditional: exportation?.amountAditional,
      idAduana: exportation?.idAduana,
      countryId: exportation?.countryId,
      countryName: exportation?.country?.nameCountry,
      customerReference: exportation?.customerReference,
      colorId: exportation?.colorId,
      approvalNumber: exportation?.approvalNumber,
      sealNumber: exportation?.sealNumber,
      userId: exportation?.userId,
      placeExId: exportation?.placeExId,
      descriptionAditional: exportation?.descriptionAditional,
      dateLoading: exportation?.dateLoading
        ? moment(exportation?.dateLoading)
        : null,
      noteLoading: exportation?.noteLoading,

      // datos adicionales

      departureDate: exportation?.departureDate
        ? moment(exportation?.departureDate)
        : null,
      arrivalDate: exportation?.arrivalDate
        ? moment(exportation?.arrivalDate)
        : null,
      dateNotArrive: exportation?.dateNotArrive
        ? moment(exportation?.dateNotArrive)
        : null,
      dateNotShipment: exportation?.dateNotShipment
        ? moment(exportation?.dateNotShipment)
        : null,
      dateNotEmtied: exportation?.dateNotEmtied
        ? moment(exportation?.dateNotEmtied)
        : null,
      dateReceivingRTU: exportation?.dateReceivingRTU
        ? moment(exportation?.dateReceivingRTU)
        : null,
      dateUpdateRTU: exportation?.dateUpdateRTU
        ? moment(exportation?.dateUpdateRTU)
        : null,
      dateNotZarpe: exportation?.dateNotZarpe
        ? moment(exportation?.dateNotZarpe)
        : null,
      containerDesc: exportation?.containerDesc,
      descriptionGoods: exportation?.descriptionGoods,
      idSubShip: exportation?.idSubShip,
      idImpNp: exportation?.idImpNp,
      idSubFa: exportation?.idSubFa,
      idImpUc: exportation?.idImpUc,
      serviceId: exportation?.serviceId,
      modeTransportationId: exportation?.modeTransportationId,
      pointOrigenFtzNum: exportation?.pointOrigenFtzNum,
      placeReceiptCarrier: exportation?.placeReceiptCarrier,
      portLoadingId: exportation?.portLoadingId,
      vesselNameFlag: exportation?.vesselNameFlag,
      preCarriageBy: exportation?.preCarriageBy,
      loadingPierTerminal: exportation?.loadingPierTerminal,
      idCarrier: exportation?.idCarrier,
      voyageIdenticication: exportation?.voyageIdenticication,
      portUnloadingId: exportation?.portUnloadingId,
      portOriginCode: exportation?.portOriginCode,
      onCarriageBy: exportation?.onCarriageBy,
      portDestinationCode: exportation?.portDestinationCode,
      domesticRouting: exportation?.domesticRouting,
      placeDelivery: exportation?.placeDelivery
    });
  }

  const fetch = async (exportation) => {
    // CAPTURAMOS SUBASTAS
    const dataSubasta = await getSubastas();
    const optionSubasta = dataSubasta
      .filter((e) => !!e.shipper)
      .map((element) => ({
        value: element.subId,
        label: `${element.subName} - ${element.codeState}`,
      }));
    setSelectSubasta(optionSubasta);

    // CAPTURAMOS LA IMPORTADORA
    const dataImportadora = await getImportadora();
    const optionImportadora = dataImportadora
      .filter((e) => !!e.consignee)
      .map((element) => ({
        value: element.id,
        label: element.impName,
      }));
    setSelectImportadora(optionImportadora);

    // CAPUTARAMOS EL TIPO DE SERVICIO
    const dataServiceType = await getServiceType();
    const optionServiceType = dataServiceType.map((element) => ({
      value: element.serviceId,
      label: element.name,
    }));
    setSelectServiceType(optionServiceType);

    //CAPTURAMOS EL MODO DE TRASNPORTACION
    const dataModeTransportation = await getModeTransportation();
    const optionModeTransportation = dataModeTransportation.map((element) => ({
      value: element.modeTransportationId,
      label: `${element.name} - METHOD: ${element.method} - CODE: ${element.code}`.toUpperCase(),
    }));
    setSelectModeTransportation(optionModeTransportation);

    //CAPTURAMOS EL PORTCODE
    const dataPortCode = await getPortCode();

    const optionPortCode = dataPortCode
      .filter((element) => element.typeState === "Origin")
      .map((element) => ({
        value: element.portId,
        label: element.name,
        typeState: element.typeState,
        port: element.port,
      }));
    setSelectPortCode(optionPortCode);
    const optionPortCodeDestination = dataPortCode
      .filter(
        (element) =>
          element.typeState === "Destination" &&
          element.country === exportation?.codeCountry
      )
      .map((element) => ({
        value: element.portId,
        label: element.name,
        typeState: element.typeState,
        port: element.port,
      }));

    setSelectPortCodeDestination(optionPortCodeDestination);
    form &&
    form.setFieldsValue({
      portUnloadingId: optionPortCodeDestination.filter(
        (element) => element.port === exportation?.portDestinationCode
      )[0]?.value
        ? optionPortCodeDestination.filter(
          (element) => element.port === exportation?.portDestinationCode
        )[0]?.value
        : null,
    });
  };

  const handleSelectPortCode = (portCode) => setPortCode(portCode);

  const onSelectPortDestination = (value, option) => {
    form.setFieldsValue({
      portDestinationCode: option.port,
    });
  };

  const onSelectedPort = (value, option) => {
    form.setFieldsValue({
      portOriginCode: option.port,
    });
  };

  const handleUpdateImportadora = (values) => {
    fetch().then(() => {
      form.setFieldsValue({
        idImpUc: values.id,
        idImpNp: values.id,
      });
    });
    setOptionSelectDisableNotifyParty(true);
    setShowAddConsignee(false);
  };

  const onSavePortCode = (values) => {
    fetch().then(() => {
      form.setFieldsValue({
        portUnloadingId: values.portId,
      });
    });

    setPortCode(undefined);
  };

  return(
    <>
      <Spinner loading={loading}>
        <Form
          layout={"vertical"}
          form={form}
          onFinish={updateAndSaveInformationAditional}
          autoComplete="off">
          <Row gutter={[16, 24]}>
            <Divider>Entities</Divider>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Shipper" name="idSubShip">
                <Select
                  showSearch
                  placeholder="Shipper"
                  optionFilterProp="children"
                  onChange={(value) => {
                    form.setFieldsValue({
                      idSubFa: value,
                    });
                    setOptionSelectDisableForwading(true);
                  }}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionSelectSubasta}
                />
              </Form.Item>

              <Form.Item label="Forwarding Agent" name="idSubFa">
                <Select
                  disabled={optionSelectDisableForwading}
                  showSearch
                  placeholder="Forwarding Agent"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionSelectSubasta}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={20}>
                  <Form.Item label="Ultimate Consignee" name="idImpUc">
                    <Select
                      showSearch
                      placeholder="Ultimate Consignee"
                      optionFilterProp="children"
                      onChange={(value) => {
                        form.setFieldsValue({
                          idImpNp: value,
                        });
                        setOptionSelectDisableNotifyParty(true);
                      }}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      options={optionSelectImportadora}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label=" ">
                    <Button
                      block
                      type="primary"
                      icon={<PlusOutlined style={{fontSize: 17}}/>}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setShowAddConsignee(true);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Notify Party" name="idImpNp">
                <Select
                  disabled={optionSelectDisableNotifyParty}
                  showSearch
                  placeholder="Notify Party"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionSelectImportadora}
                />
              </Form.Item>
            </Col>

            <Divider>Routing</Divider>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Service Type" name="serviceId">
                <Select
                  showSearch
                  placeholder="Select Service Type"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionSelectServiceType}
                />
              </Form.Item>
              <Form.Item label="Domestic Routing/Export Instructions" name="domesticRouting">
                <Select
                  showSearch
                  placeholder="Select Domestic Routing"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    {
                      label: "RELEASE ORIGINALS UNRATED @ ORIGIN",
                      value: "RELEASE ORIGINALS UNRATED @ ORIGIN",
                    },
                    {
                      label: "RELEASE ORIGINALS UNRATED @ DESTINATION",
                      value: "RELEASE ORIGINALS UNRATED @ DESTINATION",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Mode of Transportation" name="modeTransportationId">
                <Select
                  showSearch
                  placeholder="Select Mode of Transportation"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionSelectModeTransportation}
                />
              </Form.Item>
            </Col>

            <Divider>Origin</Divider>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Point of origin or FTZ Number:" name="pointOrigenFtzNum">
                <Input placeholder="Point of origin or FTZ Number"/>
              </Form.Item>

              <Form.Item label="Place of Receipt by Pre-Carrier:" name="placeReceiptCarrier">
                <Input placeholder="Place of Receipt by Pre-Carrier"/>
              </Form.Item>

              <Form.Item label="Port of Loading" name="portLoadingId">
                <Select
                  showSearch
                  placeholder="Port of Loading"
                  optionFilterProp="children"
                  onSelect={onSelectedPort}
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionSelectPortCode}
                />
              </Form.Item>

              <Form.Item label="Vessel Name and Flag:" name="vesselNameFlag">
                <Input placeholder="Vessel Name and Flag"/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Pre-Carriage by:" name="preCarriageBy">
                <Input placeholder="Pre-Carriage by"/>
              </Form.Item>

              <Form.Item
                label="Loading Pier or Terminal:"
                name="loadingPierTerminal"
              >
                <Input placeholder="Loading Pier or Terminal"/>
              </Form.Item>

              <Form.Item
                label="Voyage Identification:"
                name="voyageIdenticication"
              >
                <Input placeholder="Voyage Identificationer"/>
              </Form.Item>
            </Col>

            <Divider>Destination</Divider>
            <Col className="gutter-row" span={12}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={20}>
                  <Form.Item label="Port of Unloading" name="portUnloadingId">
                    <Select
                      showSearch
                      placeholder="Port of Unlading"
                      optionFilterProp="children"
                      onSelect={onSelectPortDestination}
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={optionSelectPortCodeDesrtination}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Form.Item label=" ">
                    <Button
                      type="primary"
                      onClick={() => handleSelectPortCode({})}
                      block
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{color: "white"}}
                      />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Origin:" name="portOriginCode">
                <Input placeholder="Origin" disabled/>
              </Form.Item>

              <Form.Item label="Place of Delivery" name="placeDelivery">
                <Select
                  showSearch
                  placeholder="Place of Delivery by on Carrier"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  options={optionSelectPortCodeDesrtination.map(e => ({ value: e.label, label: e.label }))}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="On Carriage by:" name="onCarriageBy">
                <Input placeholder="On Carriage by"/>
              </Form.Item>

              <Form.Item label="Destination:" name="portDestinationCode">
                <Input placeholder="Destination" disabled/>
              </Form.Item>
            </Col>

            <Divider>Aviso</Divider>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Semana de Notificación" name="notificationWeek">
                <Input placeholder="Semana de Notificación" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Fecha de Notificacion arribo:"
                name="dateNotArrive"
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>

              <Form.Item
                label="Fecha Aviso de Embarque:"
                name="dateNotShipment"
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>

              <Form.Item label="Fecha de vaciado:" name="dateNotEmtied">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Fecha recibir RTU:" name="dateReceivingRTU">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>

              <Form.Item label="Fecha Modificación RTU:" name="dateUpdateRTU">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>

              <Form.Item
                label="Fecha Notificacion atraso Zarpe:"
                name="dateNotZarpe"
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{width: "100%"}}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item label="Description Container:" name="containerDesc">
                <TextArea rows={5} placeholder="Description Container"/>
              </Form.Item>
              <Form.Item
                label="Description of Goods:"
                name="descriptionGoods"
              >
                <TextArea rows={6}/>
              </Form.Item>
            </Col>
          </Row>
          <Divider></Divider>

          <Form.Item>
            <Space direction="vertical" style={{width: "100%"}}>
              <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={12}>
                  <Button onClick={goBack} block>
                    Atras
                  </Button>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{backgroundColor: "green"}}
                  >
                    Actualizar
                  </Button>
                </Col>
              </Row>
            </Space>
          </Form.Item>
        </Form>
      </Spinner>

      <PortCodeForm
        open={!!portCode}
        forDestination={true}
        country={exportation?.codeCountry}
        onSave={onSavePortCode}
        onCancel={() => setPortCode(undefined)}
      />
      <Modal
        open={showAddConsignee}
        title={'Consignee'}
        onCancel={() => {
          setShowAddConsignee(false);
        }}
        footer={null}
      >
        <ImportadoraForm titulo={'Consignee'}
                         esConsignee={true}
                         country={exportation?.countryId?.toString()}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => {
                           setShowAddConsignee(false);
                         }}/>
      </Modal>
    </>
  );
}

export default ExportationAdditionalForm;
