import Form from "react-bootstrap/Form";
import {Button, Col, DatePicker, Row, Select, Table} from "antd";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faFileExcel, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getDetalleExport, getListCarrier} from "../../../Servicios/ExportationServices";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import moment from "moment";
import selectFilterOption from "../../../shared/utils/select-filter-options";
import {getExportationContainer} from "../../../Servicios/ExportationContainerService";
import {showAduana, showCountries, showOrigen} from "../../../Servicios/CombosService";
const XLSX = require("xlsx");

interface ReporteEmbarqueFilters {
  toDate: Date | undefined;
  fromDate: Date | undefined;
  carrierId: number;
  origenId: number;
  aduanaId: number;
  countryId: number;
}

const ReporteEmbarquePage = () => {
  const [loading, setLoading] = useState(false);
  const [aduanas, setAduanas] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [detalleExports, setDetalleExports] = useState([]);

  const [filters, setFilters] = useState({
    toDate: localStorageFilter('reporteEmbarque:filter', 'toDate', true),
    fromDate: localStorageFilter('reporteEmbarque:filter', 'fromDate', true),
    carrierId: localStorageFilter('reporteEmbarque:filter', 'carrierId') ?? 0,
    origenId: localStorageFilter('reporteEmbarque:filter', 'origenId') ?? 0,
    aduanaId: localStorageFilter('reporteEmbarque:filter', 'aduanaId') ?? 0,
    countryId: localStorageFilter('reporteEmbarque:filter', 'countryId') ?? 0,
  });

  useEffect(() => {
    const localFilters = localStorageFilter();

    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem('reporteEmbarque:filter', JSON.stringify(filters));
    }
  }, [filters]);

  useEffect(() => {
    init().then();
  }, []);

  const showData = async (filters: ReporteEmbarqueFilters) => {
    setLoading(true);
    const data = await getDetalleExport(
      0,
      0,
      0,
      0,
      "",
      filters.aduanaId,
      filters.carrierId,
      filters.fromDate ? filters.fromDate.getTime() : 0,
      filters.toDate ? filters.toDate.getTime() : 0,
      filters.origenId,
      filters.countryId,
    );
    setLoading(false);
    setDetalleExports(data.list);
  }

  const init = async () => {
    if (carriers.length === 0) {
      const carrierList = await getListCarrier();
      setCarriers(carrierList);
    }

    if (origenes.length === 0) {
      const origenList = await showOrigen();
      setOrigenes(origenList);
    }

    if (countries.length === 0) {
      const countryList = await showCountries();
      setCountries(countryList);
    }

    if (aduanas.length === 0) {
      const aduanaList = await showAduana();
      setAduanas(aduanaList);
    }
  }

  const handleChangeRangePicker = (event) => {
    if (event) {
      setFilters({
        ...filters,
        fromDate: event[0].$d,
        toDate: event[1].$d
      });
    } else {
      setFilters({
        ...filters,
        fromDate: undefined,
        toDate: undefined
      });
    }
  }

  const handleSelect = (value, key) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  }

  const handleGenerarReporte = () => {
    showData(filters).then();
  }

  const handleExportarExcel = async () => {
    setLoading(true);
    const data = await getExportationContainer(
      0,
      0,
      0,
      0,
      "",
      filters.aduanaId,
      filters.carrierId,
      filters.origenId,
      filters.fromDate ? filters.fromDate.getTime() : 0,
      filters.toDate ? filters.toDate.getTime() : 0,
      filters.countryId,
    );
    setLoading(false);

    const list = [];
    data.list.forEach(e => {
      list.push({
        '1': `${e?.exportation?.shipmentName} / ${e?.carrier?.oceanCarrierName} / ${e?.container?.nameContainer} / ${e?.containerNumber} / ${e?.exportation?.bookingNum} / ${e?.exportation?.countryName} / ${e?.exportation?.aduanaName} / ETD: ${moment(e?.exportation?.departureDate).format('DD-MM-yyyy')} / ETA: ${moment(e?.exportation?.arrivalDate).format('DD-MM-yyyy')} (${e.detalleExport.length})`,
        '2': '',
        '3': '',
        '4': '',
        '5': ''
      });

      e.detalleExport.forEach(detalleExport => {
        list.push({
          '1': detalleExport?.werehouse?.vehiculoName,
          '2': detalleExport?.werehouse?.vin,
          '3': detalleExport?.werehouse?.lote,
          '4': detalleExport?.werehouse?.impName,
          '5': 1
        });
      });

      list.push({
        '1': '',
        '2': '',
        '3': '',
        '4': '',
        '5': e.detalleExport.length
      });
    });

    const origen = filters.origenId === 0
      ? origenes.map(e => e.origenName).join('/')
      : origenes.find(e => e.id === filters.origenId).nameOrigen;

    const country = filters.countryId === 0
      ? ''
      : countries.find(e => e.id === filters.countryId).nameCountry;

    list.unshift({ '1': 'Descripcion', '2': 'VIN', '3': 'Lote', '4': 'Nombre Consignatario', '5': 'Piezas' });
    list.unshift({});
    list.unshift({});
    list.unshift({ '1': moment(new Date()).format("LL"), '2': '', '3': '', '4': '', '5': '' });
    list.unshift({ '1': `${moment(filters.fromDate).format("DD MMM yyyy")} hasta ${moment(filters.toDate).format("DD MMM yyyy")} (USD)`, '2': '', '3': '', '4': '', '5': '' });
    list.unshift({ '1': `${origen} ACE INFO ${country}`.toUpperCase(), '2': '', '3': '', '4': '', '5': '' });
    list.unshift({ '1': 'MATUS INTERNATIONAL INC.', '2': '', '3': '', '4': '', '5': '' });


    const worksheet = XLSX.utils.json_to_sheet(list, {
      skipHeader: true,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Coordinaciones Sin Titulo");

    // Genera el archivo Excel
    XLSX.writeFile(workbook, "coordinaciones.xlsx", {
      bookType: "xlsx",
      type: "blob",
    });
  }

  const columns = [
    {
      key: 'loadingDate',
      dataIndex: 'loadingDate',
      title: 'Fecha de Carga',
      width: '190px',
      render: (_, element) => element?.exportationContainer?.loadingDate
        ? moment(element?.exportationContainer?.loadingDate).format('DD MMM yyyy')
        : '---'
    },
    {
      key: 'shipmentName',
      dataIndex: 'shipmentName',
      title: 'Exportation',
      width: '160px',
      render: (_, element) => element?.exportation?.shipmentName
    },
    {
      key: 'lote',
      dataIndex: 'lote',
      title: 'Lote',
      width: '160px',
      render: (_, element) => element?.werehouse?.lote
    },
    {
      key: 'vehiculoName',
      dataIndex: 'vehiculoName',
      title: 'Vehículo',
      width: '240px',
      render: (_, element) => element?.werehouse?.vehiculoName
    },
    {
      key: 'nameContainer',
      dataIndex: 'nameContainer',
      title: 'Contenedor',
      width: '180px',
      render: (_, element) => element?.exportationContainer?.container?.nameContainer
    },
    {
      key: 'containerNumber',
      dataIndex: 'containerNumber',
      title: '# de Contenedor',
      width: '180px',
      render: (_, element) => element?.exportationContainer?.containerNumber
    },
    {
      key: 'bookingNum',
      dataIndex: 'bookingNum',
      title: '# de Booking',
      width: '180px',
      render: (_, element) => element?.exportation?.bookingNum
    },
    {
      key: 'nameAduana',
      dataIndex: 'nameAduana',
      title: 'Destino',
      width: '220px',
      render: (_, element) => `${element?.exportationContainer?.aduana?.nameAduana} ${element?.exportationContainer?.aduana?.countryName}`
    },
    {
      key: 'oceanCarrierName',
      dataIndex: 'oceanCarrierName',
      title: 'Naviera',
      width: '220px',
      render: (_, element) => `${element?.exportationContainer?.carrier?.oceanCarrierName} (${element?.exportationContainer?.carrier?.oceanCarrierCode})`
    },
    {
      key: 'departureDate',
      dataIndex: 'departureDate',
      title: 'Fecha de Llegada (ETD)',
      width: '200px',
      render: (_, element) => element?.exportation?.departureDate
        ? moment(element?.exportation?.departureDate).format('DD MMM yyyy')
        : '---'
    },
    {
      key: 'arrivalDate',
      dataIndex: 'arrivalDate',
      title: 'Fecha de Salida (ETA)',
      width: '200px',
      render: (_, element) => element?.exportation?.arrivalDate
        ? moment(element?.exportation?.arrivalDate).format('DD MMM yyyy')
        : '---'
    }
  ];

  return(
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="mb-0">Reporte de Embarque</h5>
      </div>
      <hr/>
      <Row gutter={16}>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={8} xl={8}>
          <Form.Label>Filtrar por Fecha</Form.Label>
          <DatePicker.RangePicker className="w-100"
                                  onChange={handleChangeRangePicker} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Origen</Form.Label>
            {
              filters.origenId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleSelect(0, 'origenId')} />
            }
          </div>
          <Select showSearch={true}
                  className="w-100"
                  filterOption={selectFilterOption}
                  value={filters.origenId === 0 ? "" : filters.origenId}
                  onChange={value => handleSelect(value, 'origenId')}
                  options={origenes.map(e => {
                    return {
                      value: e.id,
                      label: e.nameOrigen
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por País</Form.Label>
            {
              filters.countryId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleSelect(0, 'countryId')} />
            }
          </div>
          <Select showSearch={true}
                  className="w-100"
                  filterOption={selectFilterOption}
                  value={filters.countryId === 0 ? "" : filters.countryId}
                  onChange={value => handleSelect(value, 'countryId')}
                  options={countries.map(e => {
                    return {
                      value: e.id,
                      label: e.nameCountry
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Naviera</Form.Label>
            {
              filters.carrierId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleSelect(0, 'carrierId')} />
            }
          </div>
          <Select showSearch={true}
                  className="w-100"
                  filterOption={selectFilterOption}
                  value={filters.carrierId === 0 ? "" : filters.carrierId}
                  onChange={value => handleSelect(value, 'carrierId')}
                  options={carriers.map(e => {
                    return {
                      value: e.carrierId,
                      label: e.oceanCarrierName
                    }
                  })} />
        </Col>
        <Col className="mt-3" xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label>Filtrar por Destino</Form.Label>
            {
              filters.aduanaId !== 0 &&
              <FontAwesomeIcon icon={faTimes}
                               className="cursor-pointer text-danger"
                               onClick={() => handleSelect(0, 'aduanaId')} />
            }
          </div>
          <Select showSearch={true}
                  className="w-100"
                  filterOption={selectFilterOption}
                  value={filters.aduanaId === 0 ? "" : filters.aduanaId}
                  onChange={value => handleSelect(value, 'aduanaId')}
                  options={aduanas.filter(e => filters.countryId === 0 || e.countryId === filters.countryId).map(e => {
                    return {
                      value: e.id,
                      label: e.nameAduana
                    }
                  })} />
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-end gap-2 my-3">
        <Button type={'primary'} onClick={handleGenerarReporte}>
          <FontAwesomeIcon className="me-2" icon={faFile}/> Generar Reporte
        </Button>
        <Button type={'primary'} loading={loading} onClick={handleExportarExcel}>
          <FontAwesomeIcon className="me-2" icon={faFileExcel}/> Exportar a Excel
        </Button>
      </div>
      <Table columns={columns}
             scroll={{x: 1600}}
             dataSource={detalleExports}
             size={'small'}
             loading={loading}
             pagination={{ pageSize: 10, pageSizeOptions: [10, 50, 100, 150, 200, 500] }} />
    </>
  );
}

export default ReporteEmbarquePage;
