import ExportationTable from "../ExportationTable";
import {useEffect, useState} from "react";
import useCurrentUser from "../../../Hooks/UserHook";
import {getExportations} from "../../../Servicios/ExportationServices";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import Spinner from "../../../shared/spinner";

const NotificacionEmbarque = () => {
  const currentUser = useCurrentUser();

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [exportations, setExportations] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    if (currentUser?.idubc) {
      initExportations().then();
    }
  }, [currentUser?.idubc, pagination.current]);

  const initExportations = async () => {
    setLoading(true);
    const data = await getExportations(
      currentUser?.idubc,
      pagination.current,
      pagination.pageSize,
      search,
      ExportationStateEnum.ShippingInstruction
    );
    setLoading(false);
    setExportations(data.list.map((e, index) => {
      return {
        key: index,
        ...e,
      }
    }));

    setPagination({
      ...pagination,
      total: data?.totalElements,
    });
  }

  const handleTableChange = (pagination) => {
    setPagination((prevState) => (
      {
        ...prevState,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }
    ));
  }

  const handleSearch = async (value) => {
    setSearch(value);
    setPagination({...pagination, current: 1});
  }

  return(
    <>
      <Spinner loading={loading}>
        <div className={"d-flex align-items-center justify-content-between"}>
          <h5 className="mb-0">Notificacion de Embarque</h5>
        </div>
        <hr/>
        <ExportationTable exportations={exportations}
                          pagination={pagination}
                          onPageChange={handleTableChange}
                          onRefreshTable={() => initExportations(pagination.current, pagination.pageSize, search)}
                          onEditExportation={() => {
                            initExportations(pagination.current, pagination.pageSize, search).then();
                          }}/>
      </Spinner>
    </>
  )
}

export default NotificacionEmbarque;
