import {
  VerticalAlignBottomOutlined,
  HomeFilled,
  BookFilled,
  ContainerFilled,
  DollarOutlined,
  EditFilled,
  CarFilled,
  GoldenFilled,
  ReconciliationFilled,
  ShopFilled,
  CodepenOutlined,
  ExportOutlined,
  PlusSquareOutlined,
  CarOutlined,
  FileExcelFilled,
  DatabaseOutlined,
  BankOutlined
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import {getCountriesId, isAdmin, validarPermisos} from "../Servicios/AuthService";
import { permisos } from "./permisos";
import {AdminPanelSettings} from "@mui/icons-material";

const countriesId = getCountriesId();

const filtroSalvador = countriesId.indexOf('1') !== -1 || countriesId === '0';
const filtroGuatemala = countriesId.indexOf('2') !== -1 || countriesId === '0';
const filtroCostaRica = countriesId.indexOf('3') !== -1 || countriesId === '0';
const filtroNicaragua = countriesId.indexOf('4') !== -1 || countriesId === '0';
const filtroHonduras = countriesId.indexOf('5') !== -1 || countriesId === '0';
const filtroPanama = countriesId.indexOf('8') !== -1 || countriesId === '0';

const arrayMenu = [
  {
    label: "Inicio",
    icon: <HomeFilled />,
    type: "menu",
    path: "/home",
    // EN CASO DE NO TENER UN PERMISO ASIGANDO PERO QUIERES QUE SE MUESTRE PARA TODO EN GENERAL SOLO AGREGAR PERMISSION : 1
    // ESTO FUNCIONA PARA MENU PADRES E HIJOS
    permission: 1
  },
  {
    label: "Admin",
    icon: <AdminPanelSettings />,
    type: "sub",
    permission: isAdmin(),
    children: [
      {
        label: "Usuarios",
        path: "/admin/users",
        permission: isAdmin(),
      },
      {
        label: "Roles",
        path: "/admin/roles",
        permission: isAdmin(),
      },
      {
        label: "Permisos",
        path: "/admin/permissions",
        permission: isAdmin(),
      },
    ]
  },
  {
    label: "Catalogos",
    icon: <BookFilled />,
    type: "sub",
    permission: isAdmin() || validarPermisos(permisos.CATALOGO_TIPETITLES),
    children: [
      {
        label: "Puerto de Carga/Descarga",
        path: "/catalogs/portCode",
        permission: isAdmin(),
      },
      {
        label: "Títulos de Embarque",
        path: "/catalogs/title",
        permission: isAdmin() || validarPermisos(permisos.CATALOGO_TIPETITLES),
      },
      {
        label: "Carrier",
        path: "/catalogs/carrier",
        permission: isAdmin(),
      },
    ]
  },
  {
    label: "Carteras de clientes",
    icon: <BookFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_CLIENTE_SALVADOR) ||
      validarPermisos(permisos.VER_CLIENTE_GUATEMALA) ||
      validarPermisos(permisos.VER_CLIENTE_CR) ||
      validarPermisos(permisos.VER_CLIENTE) ||
      validarPermisos(permisos.VER_CLIENTE_HONDURAS),
    children: [
      {
        label: "El Salvador",
        path: "/portfolio/country/1",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_SALVADOR) && filtroSalvador,
      },
      {
        label: "Guatemala",
        path: "/portfolio/country/2",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_GUATEMALA) && filtroGuatemala,
      },
      {
        label: "Costa Rica",
        path: "/portfolio/country/3",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_CR) && filtroCostaRica,
      },
      {
        label: "Nicaragua",
        path: "/portfolio/country/4",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE) && filtroNicaragua,
      },
      {
        label: "Honduras",
        path: "/portfolio/country/5",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_HONDURAS) && filtroHonduras,
      },
      {
        label: "Panama",
        path: "/portfolio/country/8",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_PANAMA) && filtroPanama,
      },
      {
        label: "Actividad de Clientes",
        path: "/clientes/actividad",
        permission: validarPermisos(permisos.VER_CLIENTE),
      },
    ],
  },
  {
    label: "Importadoras",
    icon: <CodepenOutlined />,
    type: "menu",
    path: "/importer",
    permission: validarPermisos(permisos.AGREGAR_IMPORTADORA)
  },
  {
    label: "Aduanas",
    icon: <DatabaseOutlined />,
    type: "menu",
    path: "/customs",
    permission: validarPermisos(permisos.AGREGAR_ADUANA)
  },
  {
    label: "Solicitudes de clientes",
    icon: <EditFilled />,
    type: "menu",
    path: "/customerRequest",
    permission: 0
  },
  {
    label: "Solicitudes",
    icon: <EditFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_SOLICITUDES) ||
      validarPermisos(permisos.AGREGAR_SOLICITUDES),
    children: [
      {
        label: "Lista de solicitudes",
        path: "/requests/list",
        permission: validarPermisos(permisos.VER_SOLICITUDES),
      },
      {
        label: "Nueva solicitud",
        path: "/request",
        permission: validarPermisos(permisos.AGREGAR_SOLICITUDES),
      },
      {
        label: "Multiples solicitudes",
        path: "/multiplesSolicitudes",
        // permission: validarPermisos(permisos.MULTIPLES_SOLICITUDES),
        permission:  validarPermisos(permisos.AGREGAR_SOLICITUDES),
      },
      {
        label: "Historial de solicitudes",
        path: "/requests/list/history",
        permission: validarPermisos(permisos.VER_SOLICITUDES_HISTORIAL),
      },
      {
        label: "Reporte de solicitudes",
        path: "/requests/list/report",
        permission: validarPermisos(permisos.VER_SOLICITUDES_REPORTE),
      },
      //{
        //label: "Estados por Semana",
        //path: "/solicitud/estado-por-semana",
        //permission: validarPermisos(permisos.VER_SOLICITUDES_REPORTE),
      //}
    ],
  },
  {
    label: "Solicitudes por Ventas",
    icon: <VerticalAlignBottomOutlined />,
    type: "sub",
    permission: validarPermisos(permisos.AGREGAR_COORDINACION),
    children: [
      {
        label: "Florida",
        path: "/incomingRequests/1",
        permission: validarPermisos(permisos.AGREGAR_COORDINACION),
      },
      {
        label: "California",
        path: "/incomingRequests/2",
        permission: validarPermisos(permisos.AGREGAR_COORDINACION),
      },
    ],
  },
  {
    label: "Ingreso Directo",
    icon: <BankOutlined />,
    type: "menu",
    path: "/multipleCoordinations",
    // permission: validarPermisos(permisos.AGREGAR_TRANSPORTE)
    permission:  validarPermisos(permisos.AGREGAR_COORDINACION) || validarPermisos(permisos.INGRESO_OFICINA),
  },
  {
    label: "Domestic",
    icon: <ContainerFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_COORDINACION_FL) ||
      validarPermisos(permisos.VER_COORDINACION_CA),
    children: [
      {
        label: "Florida",
        path: "/coordinations/country/1",
        permission: validarPermisos(permisos.VER_COORDINACION_FL),
      },
      {
        label: "California",
        path: "/coordinations/country/2",
        permission: validarPermisos(permisos.VER_COORDINACION_CA),
      },
      {
        label: "Recibidos sin Título",
        path: "/coordinations/recibidos-sin-titulo",
        permission: validarPermisos(permisos.VER_COORDINACION_FL, permisos.VER_COORDINACION_CA),
      },
      {
        label: "Reporte de Storage",
        path: "/storageReport",
        permission: validarPermisos(permisos.VER_REPORTE_STORAGE),
      },
    ],
  },
  {
    label: "Contabilidad",
    icon: <DollarOutlined />,
    type: "sub",
    permission:
      validarPermisos(permisos.PAGOS_TRANSPORTISTAS),
    children: [
      {
        label: "Pagos Transportistas",
        path: "/payments/truks",
        permission: validarPermisos(permisos.PAGOS_TRANSPORTISTAS),
      },
    ],
  },
  {
    label: "Agregar transportistas",
    icon: <CarFilled />,
    type: "menu",
    path: "/addCarrier",
    permission: validarPermisos(permisos.AGREGAR_TRANSPORTE)
  },
  {
    label: "Agregar Subasta/Ubicacion",
    icon: <GoldenFilled />,
    type: "menu",
    path: "/addAuction",
    permission: validarPermisos(permisos.AGREGAR_SUBASTAS)
  },
  {
    label: "Recibo de vehiculos",
    icon: <ReconciliationFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.RECEIVING_FL) ||
      validarPermisos(permisos.RECEIVING_CA) ||
      validarPermisos(permisos.RECIBIDOS_HOY_FL) ||
      validarPermisos(permisos.RECIBIDOS_HOY_CA) ||
      validarPermisos(permisos.RECIBO_DE_VEHICULOS) ||
      validarPermisos(permisos.VER_RECIBIDOS_EN_CA),
    children: [
      {
        label: "Ingreso de Vehículos",
        path: "/vehicleReceipts",
        permission: validarPermisos(permisos.RECEIVING_FL, permisos.RECEIVING_CA),
      },
      {
        label: "Received today",
        path: "/receivedtoday",
        permission: validarPermisos(permisos.RECIBIDOS_HOY_FL, permisos.RECIBIDOS_HOY_CA, permisos.VER_RECIBIDOS_EN_CA),
      },
      {
        label: "Titulos Pendientes",
        path: "/titlesPendingCar",
        permission: validarPermisos(permisos.RECIBIDOS_HOY_FL, permisos.RECIBIDOS_HOY_CA),
      },
    ],
  },
  {
    label: "Nuevo Warehouse",
    icon: <PlusSquareOutlined />,
    type: "menu",
    path: "/newWarehouse",
    permission: validarPermisos(permisos.AGREGAR_WEREHOUSE)
  },
  {
    label: "Nuevo Vehiculo",
    icon: <CarOutlined />,
    type: "menu",
    path: "/newCar",
    permission: 0
  },
  {
    label: "Warehouse Receipt",
    icon: <ShopFilled />,
    type: "sub",
    path: "/warehouseReceipt ",
    permission: 
      validarPermisos(permisos.WAREHOUSE_CA) ||
      validarPermisos(permisos.WAREHOUSE_FL),
    children: [
        {
          label: "Florida",
          path: "/warehouseReceipt/1",
          permission: validarPermisos(permisos.WAREHOUSE_FL),
        },
        {
          label: "California",
          path: "/warehouseReceipt/2",
          permission: validarPermisos(permisos.WAREHOUSE_CA),
        },
    ],
  },
  {
    label: "Exportaciones",
    icon: <ExportOutlined />,
    type: "sub",
    path: "/exportation",
    permission: validarPermisos(permisos.EXPORTATIONS),
    children: [
      {
        label: 'Planif. de Contenedores',
        path: "/exportation/container",
        permission: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_VER),
      },
      {
        label: "Lista de Exportaciones",
        path: "/exportation/list",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
      {
        label: "En Proceso",
        path: "/exportation/aduana/list",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
      {
        label: "Validación",
        path: "/exportation/naviera/list",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
      {
        label: "Notif. de Embarques",
        path: "/exportation/notificacion-embarque",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
      {
        label: "Reporte de Embarques",
        path: "/exportation/reporte/embarque",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
  ],
  },
  //Cotizacion
  {
    label: "Cotizacion",
    icon: <FileExcelFilled />,
    type: "menu",
    path: "/quotation/list",
    permission: validarPermisos(permisos.QUOTATION)
  },
  {
    label: "Reportes",
    icon: <FileExcelFilled />,
    type: "sub",
    path: "/reports",
    permission: 0,
                //validarPermisos(permisos.REPORTE_COMENT_CLIENTES) ||
                //validarPermisos(permisos.VER_TITULOS_PENDIENTES),
    children: [
      {
        label: "Reporte de comentarios",
        path: "/reportComments",
        permission: validarPermisos(permisos.REPORTE_COMENT_CLIENTES),
      },
      {
        label: "Reporte Titulos Pendientes",
        path: "/pendingTitles",
        permission: validarPermisos(permisos.VER_TITULOS_PENDIENTES),
      },
    ]
  },
  
];

const menuItem = (array) => {
  let itemArray = [];
  let indexGlobal = 0;

  array.filter(item => item.permission).map((item, index) => {
    // validacion para un menu
    if (item.type === "menu") {
      let nameLabel = item.label;
      item.label = (
        <NavLink
          to={item.path}
          className="activeClicked"
          style={{ textDecoration: "none" }}
        >
          {nameLabel}
        </NavLink>
      );
      indexGlobal++;
      itemArray.push({ ...item, permission: '1', key: `menu-${indexGlobal}` });
    } else {
      // validacion para un sub menu

      let arrayChildren = [];
      item.children.filter(itemChildren => itemChildren.permission).map((itemChildren, indexChildren = index) => {
        let nameLabelChildren = itemChildren.label;
        itemChildren.label = (
          <NavLink
            to={itemChildren.path}
            style={{ textDecoration: "none" }}
          >
            {nameLabelChildren}
          </NavLink>
        );
        indexGlobal++;
        arrayChildren.push({ ...itemChildren, permission: '1', key: `key-${indexGlobal}` });
      });
      item.children = arrayChildren;
      itemArray.push({
        ...item,
        permission: '1',
      });
    }
  });

  return itemArray;
};

export default menuItem(arrayMenu);
