import {Button, Col, Form, Input, Modal, Row, Select, Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTurnRight, faCar, faFloppyDisk, faLeftLong, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import {useNavigate} from "react-router-dom";
import {showImportadora, showSubasta} from "../Servicios/CombosService";
import {
  showCodeExport,
  showLicenceType,
  showOrigin,
  showSchedule,
  showStates,
  showTypeMerch
} from "../Servicios/WerehouseService";
import {viewCar} from "../Servicios/SolicitudesService";
import {getListFolderId} from "../Servicios/GoogleService";
import VehicleWarehouseUpdate from "./VehicleWarehouseUpdate";
import {successMessage} from "../utils/toast-message";
import AduanaSelector from "../Componentes/AduanaSelector/AduanaSelector";
import Spinner from "../shared/spinner";

const WarehouseForm = ({ werehouse, onUpdate, onSubmit }) => {
  const navigate = useNavigate();
  const [element_form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [existLote, setExistLote] = useState(false);

  const [switchPoa, setSwitchPoa] = useState(false);
  const [switchFactura, setSwitchFactura] = useState(false);

  const [origin, setOrigin] = useState([]);
  const [states, setStates] = useState([]);
  const [subastas, setSubastas] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);
  const [typemerch, setTypemerch] = useState([]);
  const [codeexport, setCodeexport] = useState([]);
  const [licencetype, setLicencetype] = useState([]);
  const [importadoras, setImportadoras] = useState([]);

  const [vehiculo, setVehiculo] = useState(undefined);
  const [subasta, setSubasta] = useState(undefined);
  const [importadora, setImportadora] = useState(undefined);

  const [editSubasta, setEditSubasta] = useState(undefined);
  const [addSubasta, setAddSubasta] = useState(false);

  const [editImportadora, setEditImportadora] = useState(undefined);
  const [addImportadora, setAddImportadora] = useState(false);

  const [showAduanaModal, setShowAduanaModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);

  useEffect(() => {
    initPage().then();
  }, [werehouse]);

  const copyLink = async (lote) => {
    const elements = await getListFolderId(lote);

    let result = '';
    if (elements.data.length === 0) {
      result = "NO SE HAN CARGADO FOTOS PARA ESTE VEHICULO";
      setExistLote(false);
    } else {
      result = `https://drive.google.com/drive/folders/${elements.data}`;
      setExistLote(true);
    }

    return result;
  };

  const initPage = async () => {
    await init();

    if (werehouse) {
      const link = await copyLink(werehouse.lote);

      element_form.setFieldsValue({
        fecha: werehouse.fecha,
        recivedBy: werehouse.recivedBy,
        subastaId: werehouse.subastaId,
        importadoraId: werehouse.importadoraId,
        flete: werehouse.flete,
        grua: werehouse.grua,
        buyer: werehouse.buyer,
        origenName: werehouse.origenName,
        idOrigen: werehouse.idOrigen,
        aduanaCom: werehouse.aduanaCom,
        aduanaId: werehouse.aduanaId,
        vin: werehouse.vin,
        lote: werehouse.lote,
        vehiculoName: werehouse.vehiculoName,
        color: werehouse.color,
        titulo: werehouse.vehiculo.titulo,
        bio: werehouse.vehiculo.bio,
        llave: werehouse.vehiculo.llave,
        catalitico: werehouse.vehiculo.catalitico,
        peso: werehouse.weight,
        largo: werehouse.length,
        alto: werehouse.height,
        ancho: werehouse.width,
        weight: werehouse.weight,
        length: werehouse.length,
        height: werehouse.height,
        width: werehouse.width,
        quantity: "1",
        poaW: werehouse.poaW,
        tieneFactura: werehouse.tieneFactura,
        typeMerchId: 1,
        auction: werehouse.subName,
        originId: werehouse.originId,
        schedulebId: werehouse.schedulebId,
        licenceTypeId: werehouse.licenceTypeId,
        expoCodeId: werehouse.expoCodeId,
        licenceNum: "NLR",
        numLlave: werehouse?.numLlave,
        linkFotos: link,
        notas: werehouse.notas,
        costoAdicional: werehouse.costoAdicional,
        descripcionCostoAdicional: werehouse.descripcionCostoAdicional,
        valorV: werehouse.valorV,
        numTitle: werehouse.numTitle,
        stateId: werehouse.stateId,
        solicitudCostoAdicional: werehouse.solicitudCostoAdicional,
        solicitudDescripcionCostoAdicional: werehouse.solicitudDescripcionCostoAdicional,
      });

      setSubasta(werehouse.subasta);
      setVehiculo(werehouse.vehiculo);
      setImportadora(werehouse.importadora);

      setSwitchPoa(werehouse.poaW);
      setSwitchFactura(werehouse.tieneFactura);
    }
  }

  const init = async () => {
    await loadTypeMerch();
    await loadStates();
    await loadSchedule();
    await loadOrigin();
    await loadLicType();
    await loadCodeExp();
    await loadSubastas();
    await loadImports();
  }

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data);
  }
  const loadImports = async () => {
    const data = await showImportadora();
    setImportadoras(data.list);
  }
  const loadCodeExp = async () => {
    const data = await showCodeExport();
    setCodeexport(data);
  }
  const loadLicType = async () => {
    const data = await showLicenceType();
    setLicencetype(data);
  }
  const loadOrigin = async () => {
    const data = await showOrigin();
    setOrigin(data);
  }
  const loadSchedule = async () => {
    const data = await showSchedule();
    setScheduleb(data);
  }
  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  }
  const loadTypeMerch = async () => {
    const data = await showTypeMerch();
    setTypemerch(data);
  }

  const handleFinish = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
  }

  const handleSelectChangeSubs = ({value}) => {
    const subasta = subastas.find(e => e.subId === value);
    setSubasta(subasta);
  }

  const handleSelectChangeImp = ({value}) => {
    const importadora = importadoras.find(e => e.id === value);
    setImportadora(importadora);
  }

  const handleUpdateSubasta = async subasta => {
    await loadSubastas();

    setSubasta(subasta);
    element_form.setFieldsValue({
      subastaId: subasta.subId,
    });

    setAddSubasta(false);
    setEditSubasta(undefined);
  }

  const handleUpdateImportadora = async importadora => {
    await loadImports();

    setImportadora(importadora);
    element_form.setFieldsValue({
      importadoraId: importadora.id,
    });

    setAddImportadora(false);
    setEditImportadora(undefined);
  }

  const openDes = () => {
    handleShowAduanaModal();
  };

  const handleSelectAduana = async aduana => {
    const aduanaCom = aduana.nameAduana;

    element_form.setFieldsValue({
      aduanaCom: aduanaCom,
      aduanaId: aduana.id,
    });
  }

  const handleCloseAduanaModal = () => setShowAduanaModal(false);

  const handleShowAduanaModal = () => setShowAduanaModal(true);

  const handleCloseVehicleModal = () => setShowVehicleModal(false);

  const handleShowVehicleModal = () => setShowVehicleModal(true);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const mostrarVehiculo = async (vehiculoId) => {
    setLoading(true);
    const vehiculo = await viewCar(vehiculoId);
    setLoading(false);

    setVehiculo(vehiculo);
    handleShowVehicleModal();
  };

  return(
    <Spinner loading={loading}>
      <Form
        style={{width: "100%"}}
        layout="vertical"
        onFinish={handleFinish}
        autoComplete="off"
        form={element_form}
      >
        <Row gutter={16} justify={"center"}>
          <h4>Editando { werehouse?.codigo } de { werehouse?.impName }</h4>
        </Row>
        <hr/>

        <Row gutter={[16, 24]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Fecha y Hora Recibido" name="fecha">
              <Input disabled required/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Creado por" name="recivedBy">
              <Input disabled required/>
            </Form.Item>
          </Col>
        </Row>

        {/* COMPONER DE ULTIMO */}
        <Row gutter={[16, 24]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Shipper</p>
              <div>
                <Button type={'link'}
                        onClick={() => setAddSubasta(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus} /> Agregar
                </Button>
                <Button type={'link'}
                        onClick={() => setEditSubasta(subasta)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil} /> Modificar
                </Button>
              </div>
            </div>
            <Form.Item name="subastaId">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleSelectChangeSubs}
                onSearch={onSearch}
                filterOption={filterOption}
                defaultValue={{
                  label: "Seleccionar otro Shipper",
                  value: "empty",
                }}
                options={
                  subastas &&
                  subastas.length &&
                  subastas.map((subs) => ({
                    label: subs.subName + " - " + subs.direccion,
                    value: subs.subId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Consignee</p>
              <div>
                <Button type={'link'}
                        onClick={() => setAddImportadora(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus}/> Agregar
                </Button>
                <Button type={'link'}
                        onClick={() => setEditImportadora(importadora)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil}/> Modificar
                </Button>
              </div>
            </div>
            <Form.Item label="" name="importadoraId">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleSelectChangeImp}
                defaultValue={{
                  label: "Seleccionar otro Consignee",
                  value: "empty",
                }}
                name="importadoraId"
                onSearch={onSearch}
                filterOption={filterOption}
                options={
                  importadoras &&
                  importadoras.length &&
                  importadoras.map((imp) => ({
                    label: imp.impName + " - " + imp.direccion,
                    value: imp.id,
                  }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Flete $" name="flete">
              <Input required/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Grua $" name="grua">
              <Input required/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Buyer" name="buyer">
              <Input required/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Origen" name="origenName">
              <Input disabled/>
            </Form.Item>
            <Form.Item name="idOrigen" hidden>
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item label="Costo Adicional $" name="solicitudCostoAdicional">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={18} md={12} sm={12} xs={24}>
            <Form.Item label="Descripción del Costo Adicional" name="solicitudDescripcionCostoAdicional">
              <Input placeholder="descripción del costo adicional" />
            </Form.Item>
          </Col>
          <Col lg={6} md={12} sm={12} xs={24}>
            <Form.Item label="Costo Adicional 2 $" name="costoAdicional">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={18} md={12} sm={12} xs={24}>
            <Form.Item label="Descripción del Costo Adicional" name="descripcionCostoAdicional">
              <Input placeholder="descripción del costo adicional" />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Destino" name="aduanaCom">
              <Input disabled/>
            </Form.Item>
            <Form.Item name="aduanaId" hidden>
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="  ">
              <Button type="primary" onClick={openDes} block>
                {" "}
                <FontAwesomeIcon
                  icon={faArrowTurnRight}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                Actualizar aduana destino
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Button
              className="mb-3"
              onClick={() => mostrarVehiculo(werehouse.vehiculoId)}
              type="primary"
              block
              icon={<FontAwesomeIcon icon={faCar} className="me-2" />}>
              Actualizar datos de vehículo
            </Button>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="VIN" name="vin">
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Lote" name="lote">
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Vehiculo" name="vehiculoName">
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Color" name="color">
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Titulo" name="titulo">
              <Switch disabled checked={vehiculo?.titulo} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="BioH" name="bio">
              <Switch disabled checked={vehiculo?.bio} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="LLave" name="llave">
              <Switch disabled checked={vehiculo?.llave} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Catalitico" name="catalitico">
              <Switch disabled checked={vehiculo?.catalitico} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Peso"
              name="peso"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un peso",
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Largo"
              name="largo"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un largo",
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Alto"
              name="alto"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un alto",
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Ancho"
              name="ancho"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un ancho",
                },
              ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una cantidad",
                },
              ]}
            >
              <Input
                type="number"
                value={werehouse?.quantity}
                // required
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Tiene POA?" name="poaW">
              <Switch checked={switchPoa} onChange={value => setSwitchPoa(value)} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Tiene Factura?" name="tieneFactura">
              <Switch checked={switchFactura} onChange={value => setSwitchFactura(value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label="Tipo de Vehiculo" name="typeMerchId">
              <Select
                name="typeMerchId"
                defaultValue={{
                  label: "Seleccionar otro tipo de Vehiculo",
                  value: "empty",
                }}
                value={werehouse?.typeMerchId}
                options={
                  typemerch &&
                  typemerch.length &&
                  typemerch.map((tyme) => ({
                    label: tyme.typeMerchName,
                    value: tyme.typeMerchId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Numero de Titulo"
              name="numTitle"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un numero de titulo",
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Estado del Titulo"
              name="stateId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un estado",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro estado",
                  value: "empty",
                }}
                options={
                  states &&
                  states.length &&
                  states.map((ste) => ({
                    label: ste.state,
                    value: ste.stateId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Valor del Vehiculo"
              name="valorV"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un valor del vehiculo",
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Subasta"
              name="auction"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una subasta del vehiculo",
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Origen"
              name="originId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un origen",
                },
              ]}
            >
              <Select
                name="originId"
                defaultValue={{
                  label: "Seleccionar otro origen",
                  value: "empty",
                }}
                value={werehouse?.originId}
                options={
                  origin &&
                  origin.length &&
                  origin.map((orn) => ({
                    label: orn.originName,
                    value: orn.originId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Schedule B"
              name="schedulebId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un Schedule B",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro Schedule B",
                  value: "empty",
                }}
                options={
                  scheduleb &&
                  scheduleb.length &&
                  scheduleb.map((schb) => ({
                    label: schb.codigo,
                    value: schb.schedulebId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Tipo de Licencia"
              name="licenceTypeId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un tipo de licencia",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro tipo de Licencia",
                  value: "empty",
                }}
                options={
                  licencetype &&
                  licencetype.length &&
                  licencetype.map((lict) => ({
                    label: lict.typeCode,
                    value: lict.licenceTypeId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Codigo de Exportacion"
              name="expoCodeId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un codigo de exportacion",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro codigo de exportacion",
                  value: "empty",
                }}
                options={
                  codeexport &&
                  codeexport.length &&
                  codeexport.map((expco) => ({
                    label: expco.code,
                    value: expco.expoCodeId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Numero de Licencia"
              name="licenceNum"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un numero de licencia",
                },
              ]}
            >
              <Input required/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Numero de Llave"
              name="numLlave"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un numero de llave",
                },
              ]}
            >
              <Input required/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={4} md={24} sm={24} xs={24}>
            <Form.Item label="Fotos" name="fotos">
              <Switch checked={existLote}/>
            </Form.Item>
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            <Form.Item label="Link de Fotos" name="linkFotos">
              <Input.TextArea rows={4}/>
            </Form.Item>
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            <Form.Item
              label="Notas"
              name="notas"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una nota",
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                // onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify={"center"}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button
              type="primary"
              onClick={() =>
                navigate(`/warehouseReceipt/${werehouse?.idOrigen}`)
              }
              danger
              block
            >
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{
                  marginRight: 10,
                }}
              />
              Cancelar
            </Button>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              Guardar WR
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal open={addSubasta || !!editSubasta}
             title={addSubasta ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddSubasta(false);
               setEditSubasta(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <SubastaForm titulo={'Shipper'}
                     subasta={editSubasta}
                     afterSave={handleUpdateSubasta}
                     onCancel={() => {
                       setAddSubasta(false);
                       setEditSubasta(undefined);
                     }} />
      </Modal>

      <Modal open={addImportadora || !!editImportadora}
             title={addImportadora ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddImportadora(false);
               setEditImportadora(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm titulo={'Consignee'}
                         importadora={editImportadora}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => {
                           setAddImportadora(false);
                           setEditImportadora(undefined);
                         }} />
      </Modal>

      <Modal
        title={`Editando Vehículo ${vehiculo?.make} ${vehiculo?.model} año ${vehiculo?.year}`}
        open={showVehicleModal}
        onCancel={handleCloseVehicleModal}
        footer={null}
        centered
        width={"50%"}
      >
        <VehicleWarehouseUpdate vehiculo={vehiculo}
                                onClose={handleCloseVehicleModal}
                                onUpate={() => {
                                  successMessage('Vehículo actualizado correctamente');
                                  handleCloseVehicleModal();
                                  if (onUpdate) onUpdate();
                                }}></VehicleWarehouseUpdate>
      </Modal>

      <Modal
        title="Modificando Aduana Destino"
        open={showAduanaModal}
        onCancel={handleCloseAduanaModal}
        centered
        footer={null}
      >
        <AduanaSelector onClose={handleCloseAduanaModal}
                        onSelect={handleSelectAduana} />
      </Modal>
    </Spinner>
  );
}

export default WarehouseForm;
