import React, { useState, useEffect } from "react";
import {
  addWR, getWarehouseByCoordination,
  showCodeExport,
  showLicenceType,
  showOrigin,
  showSchedule,
  showStates,
  showTypeMerch,
} from "../Servicios/WerehouseService";
import { useParams, useNavigate } from "react-router-dom";
import { viewCoordi, editCoor } from "../Servicios/CoordService";
import {
  showSubasta,
  showImportadora,
} from "../Servicios/CombosService";
import useCurrentUser from "../Hooks/UserHook";

import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Button,
  Switch,
  Spin,
  Modal,
} from "antd";
import { toast } from "react-toastify";
import {
  faArrowTurnRight,
  faCar,
  faFloppyDisk,
  faLeftLong, faPencil, faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { viewCar } from "../Servicios/SolicitudesService";
import { getListFolderId } from "../Servicios/GoogleService";
import {errorMessage} from "../utils/toast-message";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import VehicleWarehouseUpdate from "./VehicleWarehouseUpdate";
import AduanaSelector from "../Componentes/AduanaSelector/AduanaSelector";

const WerehouseCoor = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);

  const [element_form] = Form.useForm();

  const notify = () =>
    toast.success("Se regitro correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyA = () =>
    toast.success("Se actualizo aduana destino", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const [subastas, setSubastas] = useState([]);
  const [importadoras, setImportadoras] = useState([]);

  const [subasta, setSubasta] = useState(undefined);
  const [importadora, setImportadora] = useState(undefined);

  const [editSubasta, setEditSubasta] = useState(undefined);
  const [addSubasta, setAddSubasta] = useState(false);

  const [editImportadora, setEditImportadora] = useState(undefined);
  const [addImportadora, setAddImportadora] = useState(false);

  const [typemerch, setTypemerch] = useState([]);
  const [codeexport, setCodeexport] = useState([]);
  const [licencetype, setLicencetype] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [states, setStates] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);

  const [vehiculo, setVehiculo] = useState(undefined);
  const [existLote, setExistLote] = useState(false);

  const [showAduanaModal, setShowAduanaModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);

  const handleCloseVehicleModal = () => {
    setShowVehicleModal(false);
    element_form.validateFields().then();
  }

  const handleShowVehicleModal = () => setShowVehicleModal(true);

  const handleCloseAduanaModal = () => setShowAduanaModal(false);

  const handleShowAduanaModal = () => setShowAduanaModal(true);

  const [warehouse, setWarehouse] = useState(undefined);
  const [coordinacion, setCoordinacion] = useState(undefined);

  const [newWR, setNewWR] = useState({
    werehouseId: "",
    codigo: "",
    fecha: "",
    recivedBy: "",
    auction: "",
    numTitle: "",
    numLlave: "",
    fotos: false,
    linkFotos: "",
    valorV: "",
    notas: "",
    quantity: "",
    licenceNum: "",
    idCoordi: id,
    schedulebId: "",
    stateId: "",
    typeMerchId: "",
    originId: "",
    licenceTypeId: "",
    expoCodeId: "",
    subastaId: null,
    importadoraId: "",
    vehiculoId: "",
    aduanaId: "",
    clienteId: "",
    idOrigen: "",
    shipped: "",
    poaW: false,
    tieneFactura: false,
    descripcionCostoAdicional: "",
    costoAdicional: "",
    solicitudCostoAdicional: "",
    solicitudDescripcionCostoAdicional: ""
  });

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data);
  };

  const loadImports = async () => {
    const data = await showImportadora();
    setImportadoras(data.list);
  };

  const loadCodeExp = async () => {
    const data = await showCodeExport();
    setCodeexport(data);

    const codeExport = data.find(e => e.isDefault);
    if (codeExport) {
      element_form.setFieldValue('expoCodeId', codeExport.expoCodeId);
    }
  };

  const loadLicType = async () => {
    const data = await showLicenceType();
    setLicencetype(data);
  };

  const loadOrigin = async () => {
    const data = await showOrigin();
    setOrigin(data);
  };

  const loadSchedule = async () => {
    const data = await showSchedule();
    setScheduleb(data);
  };

  const loadStates = async () => {
    const data = await showStates();
    setStates(data);
  };

  const loadTypeMerch = async () => {
    const data = await showTypeMerch();
    setTypemerch(data);
  };

  const handleSelectChangeSubs = ({ value }) => {
    setNewWR({ ...newWR, subastaId: value });
  };

  const handleSelectChangeImp = ({ value }) => {
    setNewWR({ ...newWR, importadoraId: value });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setNewWR({
      ...newWR,
      [name]: value,
    });
  };

  const editarcoordi = async (aduanaId, countryId) => {
    setLoading(true);
    await editCoor(id, {
      ...coordinacion,
      aduanaId: Number(aduanaId),
      countryId: Number(countryId),
    });
    setLoading(false);

    handleCloseAduanaModal();
    await coordinfo();
    notifyA();
  };

  const mostrarVehiculo = async (vehiculoId) => {
    setLoading(true);
    const vehiculo = await viewCar(vehiculoId);
    setLoading(false);

    setVehiculo(vehiculo);
    handleShowVehicleModal();
  };
 
  const openDes = () => {
    handleShowAduanaModal();
  };

  const copyLink = async (lote) => {
    const elements = await getListFolderId(lote);

    let result = '';
    if (elements.data.length === 0) {
      result = "NO SE HAN CARGADO FOTOS PARA ESTE VEHICULO";
      setExistLote(false);
    } else {
      result = `https://drive.google.com/drive/folders/${elements.data}`;
      setExistLote(true);
    }

    return result;
  };

  const init = async () => {
    setLoading(true);
    await Promise.all([
      loadSubastas(),
      loadImports(),
      loadCodeExp(),
      loadLicType(),
      loadOrigin(),
      loadSchedule(),
      loadStates(),
      loadTypeMerch()
    ]);

    await findWarehouse();
    await coordinfo(true);
  }

  useEffect(() => {
    if (id && currentUser) {
      init().then();
    }
  }, [id, currentUser]);

  const findWarehouse = async () => {
    const data = await getWarehouseByCoordination(id);

    if (data && data.status) {
      setWarehouse(data.data);
    }
  }

  const coordinfo = async (showVehicleModal = false) => {
    setLoading(true);
    const coor = await viewCoordi(id);
    setCoordinacion(coor);

    const link = await copyLink(coor.lote);

    element_form.setFieldsValue({
      fecha: coor.fechaRecived,
      recivedBy: currentUser.fullName,
      subastaId: coor.subastaId,
      importadoraId: coor.importadoraId,
      flete: coor.flete,
      grua: coor.grua,
      buyer: coor.buyer,
      origenName: coor.origenName,
      idOrigen: coor.origenId,
      aduanaCom: coor.aduanaCom,
      aduanaId: coor.aduanaId,
      vin: coor.vin,
      lote: coor.lote,
      vehiculoName: coor.vehiculoName,
      color: coor.color,
      titulo: coor.titulo,
      bio: coor.bioH,
      llave: coor.llave,
      catalitico: coor.catalitico,
      peso: coor.weight,
      largo: coor.length,
      alto: coor.height,
      ancho: coor.width,
      weight: coor.weight,
      length: coor.length,
      height: coor.height,
      width: coor.width,
      quantity: "1",
      poaW: false,
      tieneFactura: false,
      typeMerchId: 1,
      auction: coor.subs,
      originId: 1,
      schedulebId: 1,
      licenceTypeId: 1,
      licenceNum: "NLR",
      numLlave: coor?.vehiculo?.ubicacion,
      linkFotos: link,
      notas: coor.notasVehi,
      solicitudCostoAdicional: coor.costAdditional,
      solicitudDescripcionCostoAdicional: coor?.quote?.descripcionCostoAdicional
    });

    setSubasta(coor.subasta);
    setImportadora(coor.importadora);

    if (coor.fechaRecived && coor.vehiculo.estado === VehiculoEstadoEnum.recibidoEnBodega) {
      if (showVehicleModal && !warehouse) {
        await mostrarVehiculo(coor.vehiculoId);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      errorMessage('No se puede crear el warehouse por que el vehículo no ha sido recibido en bodega');
    }
  };

  const insertarWR = async (values) => {
    if (!coordinacion.fechaRecived || coordinacion.vehiculo.estado !== VehiculoEstadoEnum.recibidoEnBodega) {
      errorMessage('No se puede crear el warehouse por que el vehículo no ha sido recibido en bodega');
      return
    }

    values.fotos = existLote;
    values.idCoordi = coordinacion.id;
    values.vehiculoId = coordinacion.vehiculoId;
    values.clienteId = coordinacion.clienteId;
    values.countryId = coordinacion.countryId;
    values.shipped = 'NO EMBARCADO';

    try {
      setLoading(true);
      await addWR({
        ...values,
        importadoraId: +values.importadoraId,
        subastaId: +values.subastaId,
        typeMerchId: +values.typeMerchId,
        originId: +values.originId,
        schedulebId: +values.schedulebId,
        licenceTypeId: +values.licenceTypeId,
        expoCodeId: +values.expoCodeId,
        stateId: +values.stateId,
        quantity: +values.quantity,
        flete: +values.flete,
        grua: +values.grua,
        buyer: +values.buyer,
        costoAdicional: values.costoAdicional && values.costoAdicional !== ""
          ? +values.costoAdicional
          : null,
        solicitudCostoAdicional: values.solicitudCostoAdicional && values.solicitudCostoAdicional !== ""
          ? +values.solicitudCostoAdicional
          : null
      });
      setLoading(false);

      notify();
      navigate(`/coordinations/country/${coordinacion.origenId}`);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  };

  const handleUpdateSubasta = async subasta => {
    await loadSubastas();

    setSubasta(subasta);
    element_form.setFieldsValue({
      subastaId: subasta.subId,
    });

    setAddSubasta(false);
    setEditSubasta(undefined);
  }

  const handleUpdateImportadora = async importadora => {
    await loadImports();

    setImportadora(importadora);
    element_form.setFieldsValue({
      importadoraId: importadora.id,
    });

    setAddImportadora(false);
    setEditImportadora(undefined);
  }

  const handleSelectAduana = async aduana => {
    const countrId = aduana.countryId;
    const aduanaCom = aduana.nameAduana;

    await editarcoordi(aduana.id, countrId);

    element_form.setFieldsValue({
      aduanaCom: aduanaCom,
      aduanaId: aduana.id,
    });
  }

  const handleChangeTitulo = value => {
    console.log(value);
  }

  return (
    <Spin spinning={loading}>
      <ShowWhen show={!!warehouse}>
        <Row gutter={16} justify={"center"}>
          <div className="col-12">
            <h4>
              Warehouse de {coordinacion?.vehiculoName} Lote: {coordinacion?.lote}{" "}
            </h4>
            <p className="text-center">El warehouse de esta coordinacion ya ha sido creado, para ver dirigirse
              al siguiente enlace</p>
            <div className="text-center">
              <Button onClick={() => navigate(`/viewWereHouse/${warehouse.werehouseId}`)}>Ver Warehouse</Button>
            </div>
          </div>
        </Row>
      </ShowWhen>
      <ShowWhen show={!warehouse}>
        <div>
          <Form
            style={{width: "100%"}}
            layout="vertical"
            onFinish={insertarWR}
            autoComplete="off"
            form={element_form}
          >
            <Row gutter={16} justify={"center"}>
              <h4>
                Creando WR de {coordinacion?.vehiculoName} Lote: {coordinacion?.lote}{" "}
              </h4>
            </Row>
            <hr/>

            <Row gutter={[16, 24]}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Fecha y Hora Recibido" name="fecha">
                  <Input disabled required/>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Creado por" name="recivedBy">
                  <Input disabled required/>
                </Form.Item>
              </Col>
            </Row>

            {/* COMPONER DE ULTIMO */}
            <Row gutter={[16, 24]}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p className="mb-0">Shipper</p>
                  <div>
                    <Button type={'link'}
                            onClick={() => setAddSubasta(true)}>
                      <FontAwesomeIcon className="me-2" icon={faPlus} /> Agregar
                    </Button>
                    <Button type={'link'}
                            onClick={() => setEditSubasta(subasta)}>
                      <FontAwesomeIcon className="me-2" icon={faPencil} /> Modificar
                    </Button>
                  </div>
                </div>
                <Form.Item name="subastaId">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={handleSelectChangeSubs}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    defaultValue={{
                      label: "Seleccionar otro Shipper",
                      value: "empty",
                    }}
                    options={
                      subastas &&
                      subastas.length &&
                      subastas.map((subs) => ({
                        label: subs.subName + " - " + subs.direccion,
                        value: subs.subId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <p className="mb-0">Consignee</p>
                  <div>
                    <Button type={'link'}
                            onClick={() => setAddImportadora(true)}>
                      <FontAwesomeIcon className="me-2" icon={faPlus}/> Agregar
                    </Button>
                    <Button type={'link'}
                            onClick={() => setEditImportadora(importadora)}>
                      <FontAwesomeIcon className="me-2" icon={faPencil}/> Modificar
                    </Button>
                  </div>
                </div>
                <Form.Item label="" name="importadoraId">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={handleSelectChangeImp}
                    defaultValue={{
                      label: "Seleccionar otro Consignee",
                      value: "empty",
                    }}
                    name="importadoraId"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={
                      importadoras &&
                      importadoras.length &&
                      importadoras.map((imp) => ({
                        label: imp.impName + " - " + imp.direccion,
                        value: imp.id,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16]}>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Flete $" name="flete">
                  <Input required/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Grua $" name="grua">
                  <Input required/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Buyer" name="buyer">
                  <Input required/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Origen" name="origenName">
                  <Input disabled/>
                </Form.Item>
                <Form.Item name="idOrigen" hidden>
                  <Input/>
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={12} xs={24}>
                <Form.Item label="Costo Adicional $" name="solicitudCostoAdicional">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={18} md={12} sm={12} xs={24}>
                <Form.Item label="Descripción del Costo Adicional" name="solicitudDescripcionCostoAdicional">
                  <Input placeholder="descripción del costo adicional" />
                </Form.Item>
              </Col>
              <Col lg={6} md={12} sm={12} xs={24}>
                <Form.Item label="Costo Adicional 2 $" name="costoAdicional">
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={18} md={12} sm={12} xs={24}>
                <Form.Item label="Descripción del Costo Adicional" name="descripcionCostoAdicional">
                  <Input placeholder="descripción del costo adicional" />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="Destino" name="aduanaCom">
                  <Input disabled/>
                </Form.Item>
                <Form.Item name="aduanaId" hidden>
                  <Input/>
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item label="  ">
                  <Button type="primary" onClick={openDes} block>
                    {" "}
                    <FontAwesomeIcon
                      icon={faArrowTurnRight}
                      style={{
                        marginRight: 10,
                      }}
                    />{" "}
                    Actualizar aduana destino
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Button
                  className="mb-3"
                  onClick={() => mostrarVehiculo(coordinacion.vehiculoId)}
                  type="primary"
                  block
                  icon={
                    <FontAwesomeIcon
                      icon={faCar}
                      style={{
                        marginRight: 10,
                      }}
                    />
                  }
                >
                  Actualizar datos de vehiculo
                </Button>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="VIN" name="vin">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Lote" name="lote">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Vehiculo" name="vehiculoName">
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Color" name="color">
                  <Input disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Titulo" name="titulo">
                  <Switch disabled checked={coordinacion?.titulo} onChange={handleChangeTitulo}/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="BioH" name="bio">
                  <Switch disabled checked={coordinacion?.bioH}/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="LLave" name="llave">
                  <Switch disabled checked={coordinacion?.llave}/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Catalitico" name="catalitico">
                  <Switch disabled checked={coordinacion?.catalitico}/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Peso"
                  name="peso"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un peso",
                    },
                  ]}
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Largo"
                  name="largo"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un largo",
                    },
                  ]}
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Alto"
                  name="alto"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un alto",
                    },
                  ]}
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Ancho"
                  name="ancho"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un ancho",
                    },
                  ]}
                >
                  <Input disabled/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={12} md={12} sm={12} xs={24}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese una cantidad",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    value={newWR?.quantity}
                    // required
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Tiene POA?" name="poaW">
                  <Switch value={false}/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Form.Item label="Tiene Factura?" name="tieneFactura">
                  <Switch value={false}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item label="Tipo de Vehiculo" name="typeMerchId">
                  <Select
                    onChange={handleSelectChange}
                    name="typeMerchId"
                    defaultValue={{
                      label: "Seleccionar otro tipo de Vehiculo",
                      value: "empty",
                    }}
                    value={newWR?.typeMerchId}
                    options={
                      typemerch &&
                      typemerch.length &&
                      typemerch.map((tyme) => ({
                        label: tyme.typeMerchName,
                        value: tyme.typeMerchId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Numero de Titulo"
                  name="numTitle"
                  rules={[{
                    required: element_form.getFieldValue('titulo'),
                    message: "Por favor ingrese un numero de titulo"
                  }]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Estado del Titulo"
                  name="stateId"
                  rules={[{
                    required: element_form.getFieldValue('titulo'),
                    message: "Por favor seleccione un estado"
                  }]}
                >
                  <Select
                    defaultValue={{
                      label: "Seleccionar otro estado",
                      value: "empty",
                    }}
                    options={
                      states &&
                      states.length &&
                      states.map((ste) => ({
                        label: ste.state,
                        value: ste.stateId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Valor del Vehiculo"
                  name="valorV"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un valor del vehiculo",
                    },
                  ]}
                >
                  <Input type="number"/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Auction"
                  name="auction"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un auction del vehiculo",
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Origen"
                  name="originId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un origen",
                    },
                  ]}
                >
                  <Select
                    onChange={handleSelectChange}
                    name="originId"
                    defaultValue={{
                      label: "Seleccionar otro origen",
                      value: "empty",
                    }}
                    value={newWR?.originId}
                    options={
                      origin &&
                      origin.length &&
                      origin.map((orn) => ({
                        label: orn.originName,
                        value: orn.originId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Schedule B"
                  name="schedulebId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un Schedule B",
                    },
                  ]}
                >
                  <Select
                    defaultValue={{
                      label: "Seleccionar otro Schedule B",
                      value: "empty",
                    }}
                    options={
                      scheduleb &&
                      scheduleb.length &&
                      scheduleb.map((schb) => ({
                        label: schb.codigo,
                        value: schb.schedulebId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Tipo de Licencia"
                  name="licenceTypeId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un tipo de licencia",
                    },
                  ]}
                >
                  <Select
                    defaultValue={{
                      label: "Seleccionar otro tipo de Licencia",
                      value: "empty",
                    }}
                    options={
                      licencetype &&
                      licencetype.length &&
                      licencetype.map((lict) => ({
                        label: lict.typeCode,
                        value: lict.licenceTypeId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Codigo de Exportacion"
                  name="expoCodeId"
                  rules={[
                    {
                      required: true,
                      message: "Por favor seleccione un codigo de exportacion",
                    },
                  ]}
                >
                  <Select
                    defaultValue={{
                      label: "Seleccionar otro codigo de exportacion",
                      value: "empty",
                    }}
                    options={
                      codeexport &&
                      codeexport.length &&
                      codeexport.map((expco) => ({
                        label: expco.code,
                        value: expco.expoCodeId,
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Numero de Licencia"
                  name="licenceNum"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un numero de licencia",
                    },
                  ]}
                >
                  <Input required/>
                </Form.Item>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24}>
                <Form.Item
                  label="Numero de Llave"
                  name="numLlave"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese un numero de llave",
                    },
                  ]}
                >
                  <Input required/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col lg={4} md={24} sm={24} xs={24}>
                <Form.Item label="Fotos" name="fotos">
                  <Switch checked={existLote}/>
                </Form.Item>
              </Col>
              <Col lg={10} md={12} sm={24} xs={24}>
                <Form.Item label="Link de Fotos" name="linkFotos">
                  <Input.TextArea rows={4}/>
                </Form.Item>
              </Col>
              <Col lg={10} md={12} sm={24} xs={24}>
                <Form.Item
                  label="Notas"
                  name="notas"
                  rules={[
                    {
                      required: true,
                      message: "Por favor ingrese una nota",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    // onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} justify={"center"}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/coordinations/country/${coordinacion?.origenId}`)
                  }
                  danger
                  block
                >
                  <FontAwesomeIcon
                    icon={faLeftLong}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  Cancelar
                </Button>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                >
                  <FontAwesomeIcon
                    icon={faFloppyDisk}
                    style={{
                      marginRight: 10,
                    }}
                  />{" "}
                  Guardar WR
                </Button>
              </Col>
            </Row>
          </Form>
          <>
            <Modal
              title="Modificando Aduana Destino"
              open={showAduanaModal}
              onCancel={handleCloseAduanaModal}
              centered
              footer={null}
            >
              <AduanaSelector onClose={handleCloseAduanaModal}
                              onSelect={handleSelectAduana} />
            </Modal>

            <Modal
              title={`Editando Vehiculo ${vehiculo?.make} ${vehiculo?.model} año ${vehiculo?.year}`}
              open={showVehicleModal}
              onCancel={handleCloseVehicleModal}
              footer={null}
              centered
              width={"50%"}
            >
              <VehicleWarehouseUpdate vehiculo={vehiculo}
                                      onClose={handleCloseVehicleModal}
                                      onUpate={() => {
                                        handleCloseVehicleModal();
                                        coordinfo().then();
                                        notify();
                                      }}></VehicleWarehouseUpdate>
            </Modal>
          </>
        </div>
      </ShowWhen>

      <Modal open={addSubasta || !!editSubasta}
             title={addSubasta ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddSubasta(false);
               setEditSubasta(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <SubastaForm titulo={'Shipper'}
                     subasta={editSubasta}
                     afterSave={handleUpdateSubasta}
                     onCancel={() => {
                       setAddSubasta(false);
                       setEditSubasta(undefined);
                     }} />
      </Modal>

      <Modal open={addImportadora || !!editImportadora}
             title={addImportadora ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddImportadora(false);
               setEditImportadora(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm titulo={'Consignee'}
                         importadora={editImportadora}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => {
                           setAddImportadora(false);
                           setEditImportadora(undefined);
                         }} />
      </Modal>
    </Spin>
  );
};

export default WerehouseCoor;
